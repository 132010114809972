import moment from "moment";
import { useLocation } from "react-router-dom";
// export const apiURL =
// process.env.NODE_ENV === "development"
// ? sudo systemctl ngix restart
// "http://localhost:3589";
// "http://192.168.1.158:3589";
// let apiURL = "http://localhost:35899";
// export const apiURL = "http://localhost:45459";
export const apiURL = "https://bstg-kteb.katsinastate.gov.ng";
// let remoteEndpoint = "https://sftas-kn.herokuapp.com";X
// const apiURL =
//   process.env.NODE_ENV === "development" ? localEndpoint : remoteEndpoint;8213736988 monie

export const TOKEN_KEY = "@@auth_token";
const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  let token = localStorage.getItem(TOKEN_KEY);
  // console.log(token, "RRRRRR");
  fetch(apiURL + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((raw) => raw.json())
    .then((response) => {
      // console.log({ response });
      // alert(token);
      if (response.status >= 400) {
        error(response);
        if (response.status === 401) {
          window.location = "";
        }
      } else success(response);
    })
    .catch((err) => error(err));
};

const _updateApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  let token = localStorage.getItem(TOKEN_KEY);
  fetch(apiURL + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((raw) => raw.json())
    .then((response) => {
      // console.log({ response });
      if (response.status >= 400) {
        error(response);
        if (response.status === 401) {
          window.location = "";
        }
      } else success(response);
    })
    .catch((err) => error(err));
};

const _fetchApi = (
  url,
  // body={},
  success = (f) => f,
  error = (f) => f,
  empty = (f) => f
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  // console.log(token, "RRRRRR");
  fetch(apiURL + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    // body:JSON.stringify(body)
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
      } else {
        console.log("Empty response");
        // alert(token);
        empty();
      }
    })
    .catch((err) => {
      if (err.status === 401) {
        // logout();
        localStorage.removeItem(TOKEN_KEY);
        window.location = "";
      }
      error(err);
    });
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    if (!arrElem.head) {
      console.error("Item is missing 'head' property:", arrElem);
      continue;
    }
    mappedArr[arrElem.head] = arrElem;
    mappedArr[arrElem.head]["children"] = [];
  }


  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.head];

    if (!mappedElem) {
      console.error("Mapped element is undefined for head:", arrElem.head);
      continue;
    }

    const parentHeads = [];
    let currentParent = mappedElem;
    while (currentParent?.sub_head) {
      parentHeads.unshift(currentParent?.sub_head);
      currentParent = mappedArr[currentParent?.sub_head];
    }
    const childWithProperty = { ...mappedElem, parentHeads };

    if (mappedElem?.sub_head) {
      if (mappedArr[mappedElem.sub_head]) {
        mappedArr[mappedElem.sub_head]["children"].push(childWithProperty);
      } else {
        console.error("Parent for sub_head is undefined:", mappedElem.sub_head);
      }
    } else {
      tree.push(childWithProperty);
    }
  }

  return tree;
}


export function formatNumbers(n) {
  let num = parseFloat(n);
  if (!isNaN(num)) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
}

export function unflattenTable(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    if (arrElem?.code) {
      mappedArr[arrElem.code] = { ...arrElem, children: [] };
    }
  }

  // Create the tree structure by iterating over the array again.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.code];

    if (mappedElem) {
      // Add a custom property to each child containing parent head values
      const parentHeads = [];
      let currentParent = mappedElem;
      while (currentParent?.sub_head) {
        parentHeads.unshift(currentParent?.sub_head);
        currentParent = mappedArr[currentParent?.sub_head];
      }
      const childWithProperty = { ...mappedElem, parentHeads };

      if (mappedElem?.sub_head) {
        if (mappedArr[mappedElem.sub_head]) {
          mappedArr[mappedElem.sub_head]["children"]?.push(childWithProperty);
        } else {
          // Handle case where parent is not found
          tree.push(childWithProperty);
        }
      } else {
        tree.push(childWithProperty);
      }
    }
  }

  return tree;
}

export function unflattenTableNew(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    if (arrElem?.code) {
      mappedArr[arrElem.code] = { ...arrElem, children: [] };
    }
  }

  // Create the tree structure by iterating over the array again.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.code];

    if (mappedElem) {
      // Add a custom property to each child containing parent head values
      const parentHeads = [];
      let currentParent = mappedElem;
      while (currentParent?.sub_head) {
        parentHeads.unshift(currentParent?.sub_head);
        currentParent = mappedArr[currentParent?.sub_head];
      }
      const childWithProperty = { ...mappedElem, parentHeads };

      if (mappedElem?.sub_head) {
        if (mappedArr[mappedElem.sub_head]) {
          mappedArr[mappedElem.sub_head]["children"]?.push(childWithProperty);
        } else {
          // Handle case where parent is not found
          tree.push(childWithProperty);
        }
      } else {
        tree.push(childWithProperty);
      }
    }
  }

  // Remove children with zero values
  tree.forEach((node) => {
    console.log(node.children, "children");
    node.children = node?.children?.filter(
      (child) =>
        child.prev_year1_actual_amount !== 0 &&
        child.prev_year1_approve_amount !== 0 &&
        child.prev_year2_actual_amount !== 0 &&
        child.actual_amount !== 0 &&
        child.approve_amount !== 0 &&
        child.budget_amount !== 0 &&
        child.level < 3
    );
  });

  return tree;
}

export function formatCode(code) {
  // Convert the code to a number to handle numeric operations
  let numericCode = parseInt(code, 10);
  let trimmedCode = numericCode.toString().replace(/0+$/, "");
  if (code.endsWith("0000")) {
    const lastDigit = parseInt(trimmedCode.slice(+1), 9);
    if (lastDigit === 9 || lastDigit === 19 || lastDigit === 29) {
      trimmedCode = (numericCode / 9).toString();
    }
  }
  const endsWithSpecificDigit = /9$|19$|29$/.test(trimmedCode);
  return endsWithSpecificDigit ? numericCode.toString() : trimmedCode;
}

export function removeEmptyChildren(keysToCheck = [], data = [], level = 4) {
  const result = [];

  for (const item of data) {
    // Check if the item is a child (has a sub_head)
    if (item?.sub_head && item?.level > level) {
      // Get the values for the specified keys
      const values = keysToCheck.map((key) => item[key]);

      // Check if all specified values are 0 or ''
      const allValuesEmpty = values.every(
        (value) =>
          value === 0 || value === "" || value === 0.0 || value === null
      );

      // If all values are empty, skip adding this child to the result
      if (!allValuesEmpty) {
        result.push(item);
      }
    } else {
      // Always include parents (items without sub_head)
      result.push(item);
    }
  }

  return result;
}

function selectDeepDown(arr, head) {
  const deep = [];
  const mappedArr = {};

  // First map the nodes of the array to an object -> create a hash table.
  for (const arrElem of arr) {
    mappedArr[arrElem.head] = {
      ...arrElem,
      children: arrElem.children || [], // Ensure children is an array
    };
  }

  // Create the tree structure by iterating over the array again.
  for (const arrElem of arr) {
    const mappedElem = mappedArr[arrElem.head];

    // Add a custom property to each child containing parent head values
    const parentHeads = [];
    let currentParent = mappedElem;
    while (currentParent?.sub_head) {
      parentHeads.unshift(currentParent.sub_head);
      currentParent = mappedArr[currentParent.sub_head];
    }

    // Check if the current node's parentHeads include the specified head
    if (parentHeads.includes(head) || mappedElem.head === head) {
      const node = {
        description: mappedElem.description,
        head: mappedElem.head,
        sub_head: mappedElem.sub_head,
        parentHeads: mappedElem.children.length > 0, // Directly set this based on children
      };

      deep.push(node);
    }

    // If the element is not at the root level, add it to its parent array of children.
    if (mappedElem.sub_head) {
      const parent = mappedArr[mappedElem.sub_head];
      if (parent) {
        parent.children.push(mappedElem);
      } else {
        console.warn(`Parent not found for sub_head: ${mappedElem.sub_head}`);
      }
    }
  }

  return deep;
}


function removeParentEntries(data) {
  const childSubHeads = new Set(data.map((item) => item?.sub_head));
  const children = [];

  for (const item of data) {
    if (!childSubHeads.has(item.head)) {
      children.push(item);
    }
  }

  return children;
}

const filterData = (data) => {
  console.log(data);
  const arr = [];
  for (let i = 0; i < data.length - 1; i++) {
    let firstOne = data[i];
    let secondOne = data[i + 1];

    if (firstOne.head === secondOne?.sub_head) {
      i++; // Skip the next item if heads and subheads match
      continue;
    }

    arr?.push(firstOne);
  }

  // Add the last item since there is no item after it to compare
  arr?.push(data[data.length - 1]);

  return arr;
};

const formatNumber = (num) => {
  // Parse the input to a float and handle NaN cases
  let _num = num ? num : 0;
  let number = parseFloat(_num || 0);

  // if (isNaN(number)) {
  //   throw new Error("Invalid number");
  // }

  // Ensure the number has two decimal places
  let formattedNumber = number.toFixed(2);

  // Use regex to add thousand separators to the integer part
  formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
};

const capitalizeWords = (str) => {
  return str
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

const checkIfNull = (value) => {
  let isNull = isNaN(value);
  if (isNull) {
    return "---";
  } else {
    return formatNumber(value);
  }
};

export const bg = "success";
export const today = moment().format("YYYY-MM-DD");
export const weekAgo = moment().subtract(7, "days").format("YYYY-MM-DD");

export const filterZeroRows = (data) => {
  return data
    .map((node) => {
      if (node.children && node.children.length) {
        node.children = filterZeroRows(node.children).filter(
          (child) => !isAllZero(child)
        );
      }
      return node;
    })
    .filter(
      (node) => !isAllZero(node) || (node.children && node.children.length)
    );
};

const isAllZero = (node) => {
  return (
    (node.prev_year2_actual_amount || 0) === 0 &&
    (node.prev_year1_approve_amount || 0) === 0 &&
    (node.prev_year1_actual_amount || 0) === 0 &&
    (node.budget_amount || 0) === 0 &&
    (node.approve_amount || 0) === 0
  );
};
function numberToWords(num) {
  if (num === 0) return "zero";

  const belowTwenty = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function getBelowThousand(n) {
    let result = "";
    if (n >= 100) {
      result += belowTwenty[Math.floor(n / 100)] + " hundred ";
      n %= 100;
    }
    if (n >= 20) {
      result += tens[Math.floor(n / 10)] + " ";
      n %= 10;
    }
    if (n > 0) {
      result += belowTwenty[n] + " ";
    }
    return result.trim();
  }

  function helper(n, suffix) {
    if (n === 0) return "";
    return getBelowThousand(n) + " " + suffix + " ";
  }

  function integerToWords(num) {
    let word = "";
    word += helper(Math.floor(num / 1e9), "billion");
    num %= 1e9;
    word += helper(Math.floor(num / 1e6), "million");
    num %= 1e6;
    word += helper(Math.floor(num / 1e3), "thousand");
    num %= 1e3;
    word += getBelowThousand(num);

    return word.trim();
  }

  function decimalToWords(num) {
    let word = "";
    for (let i = 0; i < num.length; i++) {
      word += belowTwenty[num[i]] + " ";
    }
    return word.trim();
  }

  let parts = num.toString().split(".");
  let integerPart = parseInt(parts[0]);
  let decimalPart = parts[1] || "";

  let words = integerToWords(integerPart);

  if (decimalPart.length > 0) {
    words += " Kobo " + decimalToWords(decimalPart);
  }

  return words.trim();
}
export function ensureNumber(value) {
  return isNaN(value) || value === null || value === undefined
    ? 0
    : Number(value);
}

export const headerColors = ["#666", "#888", "#aaa", "#ccc", "#eee"];

export {
  _postApi,
  _fetchApi,
  unflatten,
  capitalizeWords,
  formatNumber,
  selectDeepDown,
  filterData,
  removeParentEntries,
  _updateApi,
  checkIfNull,
  numberToWords,
};
