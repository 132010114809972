/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import "./search.css";
import { FaSearch } from "react-icons/fa";

export default function SearchBar(props) {
  const {
    placeholder = "Search",
    filterText = "",
    onFilterTextChange = (f) => f,
    _ref = null,
    dataType,
  } = props;

  // const handleFilterTextChange = (e) => {
  //   onFilterTextChange(e.target.value);
  // };

  return (
    <div className="form-group has-search mb-3">
      {/* <span className="form-control-feedback">
        <FaSearch />
      </span> */}
      <input
        ref={_ref}
        className="form-control py-2 cus-icon"
        name="filterText"
        type={dataType}
        value={filterText}
        onChange={onFilterTextChange}
        placeholder={placeholder}
        style={{
          fontSize: "13px",
          borderWidth: "2px",
          height: "38px",
        }}
        {...props}
      />
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  filterText: PropTypes.string,
  onFilterTextChange: PropTypes.func,
  _ref: PropTypes.object,
};
