/* eslint-disable react/prop-types */
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import "./customChart.css";
const CustomBarChart = ({ data, width, height, uvDataKey, pvDataKey }) => {
  const formatYTick = (value) => {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + "T";
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    }
    return value;
  };

  const formatXTick = (value) => {
    const maxLength = 12;
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }
    return value;
  };

  const renderCustomLabel = ({ x, y, width, value }) => {
    let fillColor = "#FF0000"; // Default color is red

    if (parseInt(value) >= 80) {
      fillColor = "#008000"; // Green for 80%+
    } else if (parseInt(value) >= 60) {
      fillColor = "#FFD700"; // Gold for 60-80%
    }

    return (
      <g>
        <circle cx={x + width / 2} cy={y - 12} r={12} fill={fillColor} />
        <text
          x={x + width / 2}
          y={y - 10}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="8"
        >
          {`${value}%`}
        </text>
      </g>
    );
  };

  return (
    <div>
      <div className="chart-container">
        <BarChart data={data} width={width} height={height}>
          <XAxis
            dataKey="name"
            // tickFormatter={formatXTick}
            style={{ fontSize: 10, marginTop: 10 }}
            angle={-10}
          />
          <YAxis tickFormatter={formatYTick} yAxisId="left" />
          <YAxis
            tickFormatter={(value) => `${value}%`}
            orientation="right"
            yAxisId="right"
          />
          <Tooltip formatter={formatYTick} />
          <Bar yAxisId="left" dataKey={uvDataKey} fill="#5887B8" />
          <Bar yAxisId="left" dataKey={pvDataKey} fill="#F68332">
            <LabelList
              dataKey="Performance"
              content={renderCustomLabel}
              width={40}
            />
          </Bar>
          {/* Keep the Legend inside the chart */}
          <Legend verticalAlign="bottom" align="right" />
        </BarChart>
      </div>

      {/* Flexbox container for the chart and legend */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ position: "absolute", bottom: 20 }}
      >
        <div className="custom-legend d-flex align-items-center">
          <div>
            <span className="green"></span>
            Performance 80%+
          </div>
          <div>
            <span className="yellow"></span>
            Performance 60-80%
          </div>
          <div>
            <span className="red"></span>
            Performance &lt; 60%
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBarChart;
