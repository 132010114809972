/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { checkIfNull } from "redux/action/api";
import { ButtonGroup, Button } from "reactstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { newFilter } from "./ReportsAdminShareCom";
import { getNextYear } from "../getBudgetYear";

export default function CapitalRecByItem({
  type = "total_ex_by_function",
  route = "/get-report",
  options = [],
  title = "",
}) {
  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });
  const [loading, setLoading] = useState(false);
  // const tableRef = useRef();
  const years = useYear();

    useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption.title.includes("Personnel")
    ? "21%"
    : selectedOption?.title.includes("Debt")
    ? "2206%"
    : selectedOption.title.includes("Capital")
    ? "23%"
    : selectedOption.title.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        // if (data.success) {
        let _data = data?.results?.filter(
          (i) =>
            i.prev_year2_actual_amount !== null ||
            i.prev_year1_actual_amount !== null ||
            i.prev_year1_approve_amount !== null
        );
        setResults(_data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  const prev_year2_actual_amount = results?.reduce(
    (it, id) => it + parseFloat(id.prev_year2_actual_amount),
    0
  );
  const prev_year1_actual_amount = results?.reduce(
    (it, id) => it + parseFloat(id.prev_year1_actual_amount),
    0
  );
  const prev_year1_approve_amount = results?.reduce(
    (it, id) => it + parseFloat(id.prev_year1_approve_amount),
    0
  );
  const budget_amount = results?.reduce(
    (it, id) => it + parseFloat(id.budget_amount),
    0
  );
  const approve_amount = results?.reduce(
    (it, id) => it + parseFloat(id.approve_amount),
    0
  );

  const adjustment_amt = results?.reduce(
    (it, id) => it + parseFloat(id.supplementary_amount),
    0
  );
  const tableRef = useRef(null);
  const generateTable = () => {
    return (
      <table className="table table-striped table-bordered" ref={tableRef}>
        <thead>
          <tr
            style={{
              fontSize: "14px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
            }}
          >
            {/* <th style={{ backgroundColor: "#f2b629" }}>Code</th> */}
            <th style={{ backgroundColor: "#f2b629" }}>Receipt Description</th>
            <th style={{ backgroundColor: "#f2b629" }}>
              Administative Code and Description
            </th>
            <th style={{ backgroundColor: "#f2b629" }}>
              Economic Code and Description
            </th>
            <th style={{ backgroundColor: "#f2b629" }}>
              Fund Code and Description
            </th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${
              parseInt(form.budget_year) - 2
            }  Actual(₦)`}</th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${
              parseInt(form.budget_year) - 1
            } Aproved(₦)`}</th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${
              parseInt(form.budget_year) - 1
            } Actual(₦)`}</th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${parseInt(
              form.budget_year
            )} Proposed Budget`}</th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${parseInt(
              form.budget_year
            )} Adjustments Budget`}</th>
            <th style={{ backgroundColor: "#f2b629" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "12px", fontWeight: "bold" }}>
            {/* <td></td> */}
            <td>{selectedOption.title}</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }}>
              {checkIfNull(prev_year2_actual_amount)}
            </td>
            <td style={{ textAlign: "right" }}>
              {checkIfNull(prev_year1_approve_amount)}
            </td>
            <td style={{ textAlign: "right" }}>
              {checkIfNull(prev_year1_actual_amount)}
            </td>
            <td style={{ textAlign: "right" }}>{checkIfNull(budget_amount)}</td>
            <td style={{ textAlign: "right" }}>
              {checkIfNull(adjustment_amt)}
            </td>
            <td style={{ textAlign: "right" }}>
              {checkIfNull(approve_amount)}
            </td>
          </tr>
          {results?.map((item, idx) => {
            if (newFilter(item)) {
              return null;
            } else {
              return (
                <tr
                  key={`row_${item.code}_${idx}`}
                  style={{ fontSize: "11px" }}
                >
                  {/* <td>{item.code}</td> */}
                  <td>{item.description}</td>
                  <td>
                    {item.mda_name}-{item.mda_code}
                  </td>
                  <td>
                    {item.code}-{item.description}
                  </td>
                  <td>
                    {item.fund_code}-{item.fund_description}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.prev_year2_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.prev_year1_approve_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.prev_year1_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.budget_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.supplementary_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {checkIfNull(item.approve_amount)}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    );
  };

  // const tableBody = generateTableBody();
  // console.log(mdaGroups, "MDA GROUPING");

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.title)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify({ selectedOption })} */}
                <div
                  className="d-flex flex-wrap justify-content-between m-1"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <div className="col-md-2 col-6 mb-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-md-8">
                    <div className="text-center">
                      {/* <label>Select the report type</label> */}
                    </div>
                    <center>
                      <ButtonGroup>
                        {options.map((item) => (
                          <Button
                            size="sm"
                            key={item.title}
                            outline={
                              selectedOption.title !== item.title ? true : false
                            }
                            color={
                              selectedOption.title === item.title
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() =>
                              handleButtonClick(item.value, item.title)
                            }
                          >
                            {item.title}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </center>
                  </div>
                  <div className="mx-auto">
                    {/* <Button
                      style={{ color: "white" }}
                      className="text-white mb-3 ml-3"
                      size="sm"
                      color="primary"
                    >
                      <CSVLink
                        data={results && results ? results : []}
                        className="text-white"
                        filename={capitalizeWords(selectedOption.title)}
                      >
                        <FaFileDownload color="white" size="1.2rem" /> Download
                        Excel
                      </CSVLink>
                    </Button> */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                      <DropdownToggle
                        className="btn btn-primary text-white mb-3 ml-3"
                        caret
                      >
                        <FaFileDownload
                          color="white"
                          size="1.2rem"
                          className="mr-5"
                        />
                        Download
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => console.log("Option 1 clicked")}
                        >
                          <DownloadTableExcel
                            filename={selectedOption.title}
                            sheet={selectedOption.title}
                            currentTableRef={tableRef.current}
                          >
                            <span> Export excel </span>
                          </DownloadTableExcel>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => console.log("Option 2 clicked")}
                        >
                          PDF
                        </DropdownItem>
                        <DropdownItem />
                        <DropdownItem
                          onClick={() => console.log("Another option clicked")}
                        >
                          Word
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>{generateTable()}</Scrollbar>
                  )}
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
