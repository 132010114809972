import React, { useCallback, useEffect, useRef, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { BsPrinter, BsXSquare } from "react-icons/bs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import { Button, Input, Label, Spinner, Table } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import { PDFViewer } from "@react-pdf/renderer";
import MyPDF from "./ProposedPdf";
import { useYear } from "useYearList";
import { formatNumber } from "redux/action/api";
import { bg } from "redux/action/api";
import { getNextYear } from "./getBudgetYear";

export default function ApprovedBudget() {
  const navigate = useNavigate();

  const years = useYear();
  const [form, setForm] = useState({ year: moment().format("YYYY") });
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState();
  const user = useSelector((e) => e.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const tableContainerRef = useRef(null);
    useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, year: data })));
  }, []);
  const getData = useCallback(() => {
    setIsLoading(true);
    _postApi(
      `/select-appoved-buget?query_type=select`,
      { budget_year: form.year },
      (res) => {
        setResults(res.results);
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  }, [form.year]);

  useEffect(() => {
    getData();
  }, [getData]);

  const totalAmount = results?.reduce(
    (total, item) => parseFloat(item.budget_amount) + total,
    0
  );

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [print, setPrint] = useState(false);

  let data = [];

  results?.forEach((item) => {
    if (
      item.mda_name &&
      item.mda_name.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1
    )
      return;
    else data.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft =
        tableContainerRef.current.scrollWidth;
    }
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                my={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" gutterBottom color="white">
                  <center>
                    <h2>{user?.mda} </h2>
                  </center>
                </MDTypography>
              </MDBox>
              <center>
                <h5>Approved Budget </h5>
              </center>
              {print ? (
                <div>
                  <Button color="danger" onClick={() => setPrint(!print)}>
                    cancel
                  </Button>
                  <br />
                  <PDFViewer width="1000" height="600">
                    {" "}
                    {/* Adjust the width and height as needed */}
                    <MyPDF />
                  </PDFViewer>
                </div>
              ) : (
                <MDBox>
                  <MDBox pt={3}>
                    <div className=" row mb-4 p-3">
                      <div className="col-md-4 ">
                        <Label>Select year</Label>
                        <Input
                          type="select"
                          name="year"
                          value={form.year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>

                      <div className="col-md-8 my-4">
                        <SearchBar
                          placeholder="Search By MDA name or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                      </div>

                      <span style={{ fontWeight: "bold", textAlign: "right" }}>
                        TOTAL : ₦{totalAmount?.toLocaleString()}
                      </span>

                      <div
                        style={{
                          width: "100%",
                          height: 275,
                          overflowX: "scroll",
                        }}
                        ref={tableContainerRef}
                      >
                        <Table
                          style={{
                            width: "250%",
                          }}
                          bordered
                          striped
                        >
                          <thead>
                            <tr style={{ fontSize: 14, textAlign: "center" }}>
                              <th>MDA Name</th>
                              <th>Code</th>
                              <th>Economic Description</th>
                              <th>Program code</th>
                              <th>Program Description</th>
                              <th>Fund code</th>
                              <th>Fund Description</th>
                              <th>Functional code</th>
                              <th>Functional Description</th>
                              <th>Goe Code</th>
                              <th>Goe Description</th>
                              <th>Approved Budget(₦)</th>
                              <th>Utilized Amt(₦)</th>
                              <th>Variance(₦)</th>
                              <th>Utilize %</th>
                            </tr>
                          </thead>
                          {isLoading ? (
                            <Spinner color="primary" />
                          ) : (
                            <tbody>
                              {data.map((item, idx) => (
                                <tr key={idx} style={{ fontSize: 12 }}>
                                  <td> {item.mda_name}</td>
                                  <td> {item.economic_code}</td>
                                  <td> {item.economic_description}</td>
                                  <td> {item.program_code}</td>
                                  <td> {item.program_description}</td>
                                  <td> {item.fund_code}</td>
                                  <td> {item.fund_description}</td>
                                  <td> {item.function_code}</td>
                                  <td> {item.function_description}</td>
                                  <td> {item.geo_code}</td>
                                  <td> {item.geo_description}</td>

                                  <td style={{ textAlign: "right" }}>
                                    {formatNumber(item.approve_amount)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <td style={{ textAlign: "right" }}>
                                      {" "}
                                      {item.utilized}
                                    </td>
                                  </td>
                                  <td> {item.balance}</td>
                                  <td> {item.utilized_percentage}</td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </Table>
                      </div>
                    </div>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
