import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Row,
  Col,
  Table,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
// import { today } from "helper";
import { year } from "helper";
// import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";
// import { checkIfNull } from "redux/action/api";
// import { bg } from "redux/action/api";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { today, weekAgo } from "redux/action/api";
import { PDFViewer } from "@react-pdf/renderer";
import { MdCancel } from "react-icons/md";
import ReleasePDF from "layouts/Release/ReleasePDF";

function ReleaseReport() {
  const years = useYear();
  const [form, setForm] = useState({
    budget_year: year,
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    date_from: weekAgo,
    date_to: today,
    segment: "",
    approval_type: "",
    sector_type: "",
  });
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const fields = [
    {
      title: "Collection Date",
      custom: true,
      component: (ite) => <div> {ite.collection_date}</div>,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.description_code,
    },

    { title: "MDA Code", custom: true, component: (ite) => ite.mda_code },
    {
      title: "MDA Name",
      custom: true,
      component: (ite) => ite.mda_head,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    // {
    //   title: "Program Description",
    //   custom: true,
    //   component: (ite) => ite.program_description,
    // },
    // {
    //   title: "Functional code",
    //   custom: true,
    //   component: (ite) => ite.function_code,
    // },

    // { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    // {
    //   title: "Geo Description",
    //   custom: true,
    //   component: (ite) => ite.geo_description,
    // },

    {
      title: "Amount (₦)",
      custom: true,
      component: (ite, idx) => (
        <div style={{ float: "right" }}>{formatNumber(ite.amount)}</div>
      ),
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  let query_type = form.approval_type === "" ? "select" : "by_type";

  const getBudgetNew = useCallback(() => {
    setLoading(true);
    _postApi(
      `/releases-reports`,
      { ...form, query_type },
      (res) => {
        setResults(res.results);
        // console.log(res, "DATA");

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [
    query_type,
    form.mda_name,
    form.approval_type,
    form.date_from,
    form.date_to,
  ]);

  useEffect(() => {
    getBudgetNew();
  }, [getBudgetNew]);

  const handleChanges = ({ target: { value, name } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const totalAmount = results?.reduce(
    (total, item) => total + parseFloat(item?.amount),
    0
  );
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas", sector_type: form.sector_type }, "mdas", (d) => {
      setResult4(d);
    });
  }, [form.sector_type]);

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  let data = [];

  results?.forEach((item) => {
    if (
      (item.economic_description &&
        item.economic_description
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1) ||
      (item.economic_code &&
        item.economic_code.toLowerCase().indexOf(searchText.toLowerCase()) ===
          -1)
    )
      return;
    else data.push(item);
  });
  const [print, setPrint] = useState(false);

  const open = () => {
    setPrint(!print);
  };

  const close = () => {
    setPrint(false);
  };

  return (
    <DashboardLayout>
      {form.mda_name && (
        <center>
          <h2>{/* {form.mda_code} - {form.mda_name} */}</h2>
        </center>
      )}
      {print ? (
        <div>
          <Button color="danger" onClick={close}>
            <MdCancel /> Close
          </Button>

          <PDFViewer width="1150" height="600">
            <ReleasePDF data={data}/>
          </PDFViewer>
        </div>
      ) : (
        <MDBox pt={1} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    className="text-center"
                  >
                    Releases Report
                  </MDTypography>
                </MDBox>
                {/* <div className="">
                <Button
                  color="primary"
                  className="pr-5 pl-5 m-4"
                  style={{ float: "right" }}
                  onClick={() => toggle3()}
                >
                  Add New Proposal
                </Button>
              </div> */}

                <MDBox>
                  <MDBox pt={0}>
                    <div className=" row p-3">
                      <div className="col-md-4 mb-3">
                        <CustomTypeahead
                          labelKey="head"
                          label="Select MDA"
                          options={aheadData}
                          onChange={(s) => {
                            if (s.length) {
                              console.log(s);
                              setForm((p) => ({
                                ...p,
                                mda_code: s[0].code,
                                mda_name: s[0].description,
                              }));
                            }
                          }}
                        />
                      </div>

                      <div className="col-md-3 mb-3">
                        <label>Budget Type</label>
                        <select
                          name="approval_type"
                          onChange={handleChange}
                          className="form-control"
                          value={form.approval_type}
                        >
                          <option value="">...Select...</option>
                          <option value="Revenue">Recurrent</option>
                          <option value="Capital">Capital</option>
                        </select>
                      </div>
                      {/* {JSON.stringify({ query_type })} */}
                      <div className="col-md-3">
                        <Label>Date From</Label>
                        <Input
                          type="date"
                          value={form.date_from}
                          name="date_from"
                          onChange={handleChanges}
                        />
                      </div>
                      <div className="col-md-2">
                        <Label>Date To</Label>
                        <Input
                          type="date"
                          value={form.date_to}
                          name="date_to"
                          onChange={handleChanges}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <SearchBar
                            placeholder="Search By code or description"
                            filterText={searchText}
                            onChange={handleSearchChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <span style={{ fontWeight: "bold" }}>
                            TOTAL : ₦{totalAmount?.toLocaleString()}
                          </span>
                        </div>
                        <div className="col-md-4">
                          <Button
                            color="success"
                            className="mt-0"
                            style={{ float: "right" }}
                            onClick={open}
                          >
                            <FaEye /> Print
                          </Button>
                        </div>
                      </div>

                      {loading && (
                        <div className="loading-container text-center">
                          <Spinner
                            color="primary"
                            style={{ marginTop: "20px" }}
                          />{" "}
                          Loading...
                        </div>
                      )}
                      {JSON.stringify(data)}
                      <Scrollbar>
                        <CustomTable
                          fields={fields}
                          data={data}
                          type="approve"
                        />
                      </Scrollbar>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default ReleaseReport;
