export const data2 = (val, type = "bar") => {
  // console.log(val, type, '=============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  if (val && val.length) {
    if (type === "bar") {
      return [
        {
          name: "Personnel",
          "Quarterly Budget": parseFloat(val[0]?.budget_personnel).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_personnel).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_personnel / val[0]?.budget_personnel) * 100
          ).toFixed(2),
        },
        {
          name: "Overhead",
          "Quarterly Budget": val[0]?.budget_overhead,
          "Actual for Quarter": val[0]?.actual_overhead,
          Performance: parseFloat(
            (val[0]?.actual_overhead / val[0]?.budget_overhead) * 100
          ).toFixed(2),
        },
        {
          name: "Debt Service",
          "Quarterly Budget": 0,
          "Actual for Quarter": 0,
          Performance: 0,
        },
        {
          name: "Other Recurrent",
          "Quarterly Budget": val[0]?.budget_other_recurrent,
          "Actual for Quarter": val[0]?.actual_other_recurrent,
          Performance: parseFloat(
            (val[0]?.actual_other_recurrent / val[0]?.budget_other_recurrent) *
              100
          ).toFixed(2),
        },
        {
          name: "Capital",
          "Quarterly Budget": val[0]?.budget_capital,
          "Actual for Quarter": val[0]?.actual_capital,
          Performance: parseFloat(
            (val[0]?.actual_capital / val[0]?.budget_capital) * 100
          ).toFixed(2),
        },
      ];
    } else {
      const totalSpent =
        parseFloat(val[0]?.actual_personnel) +
        parseFloat(val[0]?.actual_capital) +
        // parseFloat(val[0]?.actual_other_recurrent) +
        parseFloat(val[0]?.actual_overhead);
      return [
        {
          name: "Personnel",
          value: parseFloat(
            ((val[0]?.actual_personnel / totalSpent) * 100).toFixed(2)
          ),
          color: "#5999D2",
        },
        {
          name: "Capital",
          value: parseFloat(
            ((val[0]?.actual_capital / totalSpent) * 100).toFixed(2)
          ),
          color: "#4473C5",
        },
        // {
        //   name: "Other Recurrent",
        //   value: parseFloat(
        //     ((val[0]?.actual_other_recurrent / totalSpent) * 100).toFixed(2)
        //   ),
        //   color: "#FFC600",
        // },
        { name: "Debt Service", value: 0, color: "#A8A8A8" },
        {
          name: "Overhead",
          value: parseFloat(
            ((val[0]?.actual_overhead / totalSpent) * 100).toFixed(2)
          ),
          color: "#F68332",
        },
      ];
    }
  } else return [];
};

export const data3 = (val, type = "bar") => {
  if (val && val.length) {
    const totalSpent =
      parseFloat(val[0]?.actual_admin) +
      parseFloat(val[0]?.actual_economic) +
      parseFloat(val[0]?.actual_law) +
      parseFloat(val[0]?.actual_social);
    if (type === "bar") {
      return [
        {
          name: "Administration",
          "Quarterly Budget": parseFloat(val[0]?.budget_admin).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_admin).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_admin / val[0]?.budget_admin) * 100
          ).toFixed(2),
        },
        {
          name: "Economic",
          "Quarterly Budget": parseFloat(val[0]?.budget_economic).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_economic).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_economic / val[0]?.budget_economic) * 100
          ).toFixed(2),
        },
        {
          name: "Law and Justice",
          "Quarterly Budget": parseFloat(val[0]?.budget_law).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_law).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_law / val[0]?.budget_law) * 100
          ).toFixed(2),
        },
        {
          name: "Regional",
          "Quarterly Budget": parseFloat(val[0]?.budget_regional).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_regional).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_regional / val[0]?.budget_regional) * 100
          ).toFixed(2),
        },
        {
          name: "Social",
          "Quarterly Budget": parseFloat(val[0]?.budget_social).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_social).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_social / val[0]?.budget_social) * 100
          ).toFixed(2),
        },
      ];
    } else {
      return [
        {
          name: "Administration",
          value: parseFloat(
            ((val[0]?.actual_admin / totalSpent) * 100).toFixed(2)
          ),
          color: "#5999D2",
        },
        {
          name: "Economic",
          value: parseFloat(
            ((val[0]?.actual_economic / totalSpent) * 100).toFixed(2)
          ),
          color: "#4473C5",
        },
        {
          name: "Law and Justice",
          value: parseFloat(
            ((val[0]?.actual_law / totalSpent) * 100).toFixed(2)
          ),
          color: "#A8A8A8",
        },
        {
          name: "Regional",
          value: parseFloat(
            ((val[0]?.actual_social / totalSpent) * 100).toFixed(2)
          ),
          color: "#F68332",
        },
      ];
    }
  } else return [];
};

export const data4 = (val, type = "bar") => {
  if (val && val.length) {
    const totalSpent =
      parseFloat(val[0]?.approve_gen) ||
      0 + parseFloat(val[0]?.approve_def) ||
      0 + parseFloat(val[0]?.approve_saf) ||
      0 + parseFloat(val[0]?.approve_aff) ||
      0 + parseFloat(val[0]?.approve_epro) ||
      0 + parseFloat(val[0]?.approve_comm) ||
      0 + parseFloat(val[0]?.approve_health) ||
      0 + parseFloat(val[0]?.approve_rel) ||
      0 + parseFloat(val[0]?.approve_edu) ||
      0 + parseFloat(val[0]?.approve_spro) ||
      0;
    if (type === "bar") {
      return [
        {
          name: "701 General Pub_service",
          "Quarterly Budget": parseFloat(val[0]?.approve_gen).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_gen).toFixed(2),
          Performance: 0,
        },
        {
          name: "702 Defence",
          "Quarterly Budget": parseFloat(val[0]?.approve_def).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_def).toFixed(2),
          Performance: 0,
        },
        {
          name: "703 Public Order & Safety",
          "Quarterly Budget": parseFloat(val[0]?.approve_saf).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_saf).toFixed(2),
          Performance: 0,
        },
        {
          name: "704 Economic Affairs",
          "Quarterly Budget": parseFloat(val[0]?.approve_aff).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_aff).toFixed(2),
          Performance: 64,
        },
        {
          name: "705 Enviro. Protection",
          "Quarterly Budget": parseFloat(val[0]?.approve_epro).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_epro).toFixed(2),
          Performance: 66,
        },
        {
          name: "706 Housing & Comm. Amenities",
          "Quarterly Budget": parseFloat(val[0]?.approve_comm).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_comm).toFixed(2),
          Performance: 9,
        },
        {
          name: "707 Health",
          "Quarterly Budget": parseFloat(val[0]?.approve_health).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_health).toFixed(2),
          Performance: 30,
        },
        {
          name: "708 Recreation, Culture & Religion",
          "Quarterly Budget": parseFloat(val[0]?.approve_rel).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_rel).toFixed(2),
          Performance: 90,
        },
        {
          name: "709 Education",
          "Quarterly Budget": parseFloat(val[0]?.approve_edu).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_edu).toFixed(2),
          Performance: 37,
        },
        {
          name: "710 Social Protection",
          "Quarterly Budget": parseFloat(val[0]?.approval_spro).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_spro).toFixed(2),
          Performance: 52,
        },
      ];
    } else {
      return [
        {
          name: "701 General Pub_service",
          value: parseFloat(
            ((val[0]?.approve_gen / totalSpent) * 100).toFixed(2)
          ),
          color: "#5A9BD5",
        },
        {
          name: "702 Defence",
          value: parseFloat(
            ((val[0]?.approve_def / totalSpent) * 100).toFixed(2)
          ),
          color: "#95440B",
        },
        {
          name: "703 Public Order & Safety",
          value: parseFloat(
            ((val[0]?.approve_saf / totalSpent) * 100).toFixed(2)
          ),
          color: "#A4A6A4",
        },
        {
          name: "704 Economic Affairs ",
          value: parseFloat(
            ((val[0]?.approve_aff / totalSpent) * 100).toFixed(2)
          ),
          color: "#FFC000",
        },
        {
          name: "705 Envir. Protection",
          value: parseFloat(
            ((val[0]?.approve_epro / totalSpent) * 100).toFixed(2)
          ),
          color: "#4371C4",
        },
        {
          name: "706 Housing & Comm.",
          value: parseFloat(
            ((val[0]?.approve_comm / totalSpent) * 100).toFixed(2)
          ),
          color: "#72AD40",
        },
        {
          name: "707 Health",
          value: parseFloat(
            ((val[0]?.approve_health / totalSpent) * 100).toFixed(2)
          ),
          color: "#245D8E",
        },
        {
          name: "708 Recreation Culture & Religion",
          value: parseFloat(
            ((val[0]?.approve_rel / totalSpent) * 100).toFixed(2)
          ),
          color: "#95440B",
        },
        {
          name: "709 Education",
          value: parseFloat(
            ((val[0]?.approve_edu / totalSpent) * 100).toFixed(2)
          ),
          color: "#626262",
        },
        {
          name: "710 Social Protection",
          value: parseFloat(
            ((val[0]?.approve_spro / totalSpent) * 100).toFixed(2)
          ),
          color: "#987300",
        },
      ];
    }
  } else return [];
};

export const data5 = (val, type = "bar") => {
  console.log(val, type, '=============>>>>>>vvvvbbbb>>>>>>>>>>>>>>>>>>>>>>>')
  if (val && val.length) {
    if (type === "bar") {
      return [
        {
          name: "Government Share of FAAC",
          "Quarterly Budget": parseFloat(val[0]?.budget_faac).toFixed(2),
          "Actual for Quarter": parseFloat(val[0]?.actual_faac).toFixed(2),
          Performance: parseFloat(
            (val[0]?.actual_faac / val[0]?.budget_faac) * 100
          ).toFixed(2),
        },
        {
          name: "Aid and Grants",
          "Quarterly Budget": val[0]?.budget_aid,
          "Actual for Quarter": val[0]?.actual_aid,
          Performance: parseFloat(
            (val[0]?.actual_aid / val[0]?.budget_aid) * 100
          ).toFixed(2),
        },
       
        {
          name: "Independent Revenue",
          "Quarterly Budget": val[0]?.budget_igr,
          "Actual for Quarter": val[0]?.actual_igr,
          Performance: parseFloat(
            (val[0]?.actual_igr / val[0]?.budget_igr) *
              100
          ).toFixed(2),
        },
        {
          name: "Capital Development Fund (CDF) Receipts",
          "Quarterly Budget": val[0]?.budget_cap,
          "Actual for Quarter": val[0]?.actual_cap,
          Performance: parseFloat(
            (val[0]?.actual_cap / val[0]?.budget_cap) * 100
          ).toFixed(2),
        },
      ];
    } else {
      const totalSpent =
        parseFloat(val[0]?.budget_aid) +
        parseFloat(val[0]?.budget_igr) +
        parseFloat(val[0]?.budget_cap) +
        parseFloat(val[0]?.budget_faac);
      return [
        {
          name: "Government Share of FAAC",
          value: parseFloat(
            ((val[0]?.budget_faac / totalSpent) * 100).toFixed(2)
          ),
          color: "#5999D2",
        },
        {
          name: "Aid and Grants",
          value: parseFloat(
            ((val[0]?.budget_aid / totalSpent) * 100).toFixed(2)
          ),
          color: "#4473C5",
        },
        {
          name: "Independent Revenue",
          value: parseFloat(
            ((val[0]?.budget_igr / totalSpent) * 100).toFixed(2)
          ),
          color: "#FFC600",
        },
        {
          name: "Capital Development Fund (CDF) Receipts",
          value: parseFloat(
            ((val[0]?.budget_cap / totalSpent) * 100).toFixed(2)
          ),
          color: "#F68332",
        },
      ];
    }
  } else return [];
};
export const pieChartData = [
  { name: "Start Alloc. (inc Deriv.)", value: 17, color: "#F68332" },
  { name: "VAT", value: 3, color: "#73B24B" },
  { name: "Openning Balance", value: 13, color: "#4673CA" },
  { name: "Other FAAC", value: 21, color: "#FFC600" },
  { name: "IGR", value: 46, color: "#A8A8A8" },
];

export const pieChartData1 = [
  { name: "Personnel", value: 42, color: "#5999D2" },
  { name: "Capital", value: 29, color: "#4473C5" },
  { name: "Other Recurrent", value: 2, color: "#FFC600" },
  { name: "Debt Service", value: 0, color: "#A8A8A8" },
  { name: "Overhead", value: 25, color: "#F68332" },
];

export const pieChartData2 = [
  { name: "Administration", value: 26, color: "#5999D2" },
  { name: "Economic", value: 36, color: "#4473C5" },
  { name: "Law and Justice", value: 4, color: "#A8A8A8" },
  { name: "Regional", value: 34, color: "#F68332" },
];

export const pieChartData3 = [
  { name: "701 General Pub_service", value: 33, color: "#5A9BD5" },
  { name: "702 Defence", value: 0, color: "#95440B" },
  { name: "703 Public Order & Safety", value: 5, color: "#A4A6A4" },
  { name: "704 Economic Affairs ", value: 16, color: "#FFC000" },
  { name: "705 Envir. Protection", value: 2, color: "#4371C4" },
  { name: "706 Housing & Comm.", value: 2, color: "#72AD40" },
  { name: "707 Health", value: 9, color: "#245D8E" },
  { name: "708 Recreation Culture & Religion", value: 7, color: "#95440B" },
  { name: "709 Education", value: 21, color: "#626262" },
  { name: "710 Social Protection", value: 5, color: "#987300" },
];
