/* eslint-disable react/prop-types */
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";
import { getSector } from "helper";
import { _postApi } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import CustomTypeahead from "components/CustomTypeahead";
import { BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "redux/action/api";
import { expiredSetting } from "examples/admin/helper";
import { _fetchApi } from "redux/action/api";

function BudgetForm({
  toggle,
  toggle2,
  isOpen,
  initialData = {},
  onSave = (f) => f,
  title,
  names,
}) {
  const mda = useSelector((state) => state.auth.user.mda);
  const today = moment().format("YYYY");
  const [data, setData] = useState([]);
  const [form, setForm] = useState({ ...initialData });

  useEffect(() => {
    setForm((p) => ({ ...p, ...initialData }));
  }, [initialData]);

  const [adminData, setAdminData] = useState([]);
  const [economicData, setEconomicData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [functionalData, setFunctionalData] = useState([]);

  const [results6, setResults6] = useState([]);
  const maxLength = 240;

  const getMdaData = useCallback(
    (type, callback) => {
      _postApi(
        `/select-mda-mapping?query_type=select_mda`,
        { mda_name: mda, type },
        (data) => {
          callback(data.results);
        },
        (err) => console.log(err)
      );
    },
    [mda]
  );
  const [expired, setExpired] = useState([]);

  // const getHeads = () => {
  //   _postApi(
  //     `/budgetCeiling?query_type=select`,
  //     {},
  //     (data) => {
  //       setData(data.results);
  //     },
  //     (err) => console.log(err)
  //   );
  // };

  // useEffect(() => {
  //   getSector({ sub_head: dd }, "select_subheds", (d) => {
  //     setResults1(d);
  //   });
  // }, [dd]);

  // useEffect(() => {
  //   getSector(
  //     { type: form.segment, sub_head: [sub_head_list] },
  //     "select",
  //     setResults
  //   );
  //   getHeads();
  // }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults6);
  }, []);
  const totalFunds =
    parseFloat(form.fund_amt1 || 0) +
    parseFloat(form.fund_amt2 || 0) +
    parseFloat(form.fund_amt3 || 0);
  const isBudgetExceeded = parseFloat(totalFunds) > parseFloat(form[names]);
  const isBudgetLesser = parseFloat(totalFunds) < parseFloat(form[names]);

  const handleChange = ({ target: { name, value } }) => {
    const intValue = parseFloat(value);

    // Update form state with the changed value
    setForm((prevForm) => ({ ...prevForm, [name]: intValue }));

    // Calculate the total of fund1, fund2, fund3, and fund4

    // Disable the funds inputs if the total funds exceed the budget_amount
    // alert(name)
    if (isBudgetExceeded && name !== names) {
      alert("Total funds cannot exceed the budget amount!");
      setForm((prevForm) => ({
        ...prevForm,
        [names]: "", // Clear the field that does not match `name`
      }));
    }

    // Set the funds inputs to disabled if the budget is exceeded
    // document.querySelectorAll(".fundInput").forEach((input) => {
    //   input.disabled = isBudgetExceeded;
    // });
  };

  const _handleChange = ({ target: { name, value } }) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  // const handleChanges = (name, value, index) => {
  //   const arr = [...results1];
  //   arr[index][name] = value;
  //   arr[index] = { ...arr[index], ...form };
  //   adminData(arr);
  // };

  // const handleDelete = (index) => {
  //   const arr = [...results1];
  //   arr.splice(index, 1);
  //   adminData(arr);
  // };

  const repRef = useRef();
  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();

  const handleFinalProposal = () => {
    submitForm("final-proposal");
  };

  const handleSaveAsDraft = () => {
    submitForm("draft");
  };

  const navigate = useNavigate();
  const res = { ...form, mda_name: mda };
  const { addToast } = useToasts();
  const submitForm = (status) => {
    const apiEndpoint = `/post-budget?query_type=INSERT&status=${status}`;
    _postApi(
      apiEndpoint,
      data,
      (res) => {
        if (res.success) {
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          navigate(-1);
        }
      },
      (err) => {
        console.log(err);
        alert("An error occurred!");
      }
    );
  };
  const [results4, setResults4] = useState([]);
  useEffect(() => {
    getMdaData("ADMINISTRATIVE SEGMENT", setAdminData);
    getMdaData("ECONOMIC SEGMENT", setEconomicData);
    getMdaData("GEO SEGMENT", setGeoData);
    getMdaData("PROGRAMME SEGMENT", setProgramData);
    getMdaData("FUNCTIONAL SEGMENT", setFunctionalData);
    getSector({ type: "FUND SEGMENT" }, "select", setResults4);
    getMdaData("FUND SEGMENT", setFundData);
    expiredSetting(
      { description: "Proposed Budget End Date" },
      "select",
      (d) => {
        setExpired(d.results);
      },
      (e) => {
        console.log(e);
      }
    );
  }, [getMdaData]);

  const user = useSelector((e) => e.auth.user);
  let aheadData =
    adminData &&
    adminData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData2 =
    economicData &&
    economicData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData3 =
    geoData &&
    geoData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    programData &&
    programData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData5 =
    functionalData &&
    functionalData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData6 =
    fundData &&
    fundData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadDataFund =
    results4 &&
    results4?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleDelete = (index) => {
    let item = data.filter((i, idx) => index !== idx);
    // alert(JSON.stringify(item))
    setData(item);
    console.log(index);
  };
  const handleAdd = () => {
    if (
      form.budget_amount === 0 ||
      form.budget_year === "" ||
      form.admin_description === ""
    ) {
      alert("Please fill the required fields");
    } else if (
      form.fund_code === form.fund_code2 ||
      form.fund_code === form.fund_code3 ||
      form.fund_code2 === form.fund_code3
    ) {
      alert("Fund codes must be unique. Please use different fund codes.");
    } else if (isBudgetExceeded || isBudgetLesser) {
      alert("Total funds cannot exceed or be less than budget amount!");
    } else if (
      (form.type_of_budget === "Capital" ||
        form.type_of_budget === "Capital Receipt") &&
      form.project_description === ""
    ) {
      alert("Project description cannot be empty");
    } else {
      setData((p) => [...p, form]);
      setForm({
        budget_year: today,
        admin_code: "",
        admin_description: "",
        economic_code: "",
        economic_description: "",
        program_code: "",
        program_description: "",
        function_code: "",
        function_description: "",
        fund_code: "",
        fund_description: "",
        geo_code: "",
        geo_description: "",
        budget_amount: "",
        actual_amount: "",
        start_date: "",
        end_date: "",
        mda_name: mda,
      });
      // repRef.current.clear();
      repRef.current.clear();
      repRef1.current.clear();
      repRef2.current.clear();
      repRef3.current.clear();
      repRef4.current.clear();
      repRef5.current.clear();
    }
  };

  const fields = [
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    // {
    //   title: "Functional Description",
    //   custom: true,
    //   component: (ite) => ite.function_description,
    // },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },

    {
      title: "Proposed Amount",
      custom: true,
      component: (ite, idx) => formatNumber(ite.budget_amount || 0),
    },

    {
      title: "Action",
      custom: true,
      component: (ite, idx) => (
        <div className="text-center">
          <button
            className="btn btn-danger"
            color="primary"
            onClick={() => handleDelete(idx)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const totalAmount = data?.reduce(
    (total, item) => parseFloat(item.budget_amount) + total,
    0
  );

  let _expired = expired[0]?.end_date;
  const submissionDeadline = moment(_expired);
  const daysLeft = submissionDeadline.diff(moment(), "days");
  const [ceiling, setCeiling] = useState([]);
  const [buget, setBuget] = useState([]);
  useEffect(() => {
    const encodedMda = encodeURIComponent(user?.mda);
    const encodedQueryType = encodeURIComponent("ceiling");

    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=${encodedQueryType}`,
      (res) => {
        // alert(JSON.stringify(res.results));
        setCeiling(res.results);
      },
      (err) => {
        console.error(err);
      }
    );
    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=budget`,
      (res) => {
        const budgetArray = res.results;

        const totalBudget = budgetArray.reduce((sum, item) => {
          const budgetAmount = parseInt(item.budget_amount, 10); // Convert string to integer
          return sum + budgetAmount;
        }, 0);

        setBuget(totalBudget);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);
  const [byFund, setByFund] = useState([]);
  const [byFunc, setByFunc] = useState([]);
  const getFundMap = (code) => {
    _fetchApi(
      `/get-mapping?code=${code}&type=eco_by_fund`,
      (res) => {
        setByFund(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const getFuncMap = (code) => {
    _fetchApi(
      `/get-mapping?code=${code}&type=eco_by_func`,
      (res) => {
        setByFunc(res.results);
        // alert(JSON.stringify(res.results));
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const styles = { fontSize: "12px" };
  const styles1 = { textAlign: "right" };
  const handleSave = () => {
    if (
      form.budget_amount === 0 ||
      form.budget_year === "" ||
      form.admin_description === ""
    ) {
      alert("Please fill the required fields");
    } else if (
      (form.fund_code === form.fund_code2 ||
        form.fund_code === form.fund_code3 ||
        form.fund_code2 === form.fund_code3) &&
      form.type_of_budget === "Capital"
    ) {
      alert("Fund codes must be unique. Please use different fund codes.");
    } else if (
      (isBudgetExceeded || isBudgetLesser) &&
      form.type_of_budget === "Capital"
    ) {
      alert("Total funds cannot exceed or be less than budget amount!");
    } else if (
      (form.type_of_budget === "Capital" ||
        form.type_of_budget === "Capital Receipt") &&
      form.description === ""
    ) {
      alert("Project description cannot be empty");
    } else {
      onSave(form);
      toggle2()
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader
        // toggle={toggle}
        style={{
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <center>{title}</center>
      </ModalHeader>
      <ModalBody>
        {/* {JSON.stringify(initialData)} */}
        {/* <>fund</> */}
        {/* {JSON.stringify(form)} */}

        <div className=" row mb-4 px-3">
          {/* <div className="col-md-12">
            <label style={styles}>Budget Year</label>
            <input
              className="form-control"
              type="number"
              maxLength="4"
              name="budget_year"
              value={form.budget_year}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12">
            <label>Type of Funding</label>
            <select
              className="form-control"
              value={form.type_of_funding}
              onChange={({ target: { value } }) =>
                setForm((prev) => ({
                  ...prev,
                  type_of_funding: value,
                }))
              }
            >
              <option value={"normal"}>Normal</option>
              <option value={"counterpart"}>Counterpart Funding</option>
              <option value={"Countengency Recurrent"}>
                Countengency Recurrent
              </option>
              <option value={"Countengency Capital"}>
                Countengency Capital
              </option>
            </select>
          </div>
          <div className="col-md-12">
            <lable style={styles}>Budget type</lable>
            <select
              className="form-control"
              value={form.type_of_budget}
              name="type_of_budget"
              onChange={_handleChange}
            >
              <option>...Select...</option>
              <option value="Capital">Capital</option>
              <option value="Capital Receipt">Capital Receipt</option>
              <option value="Personnel">Personnel</option>
              <option value="Revenue">Revenue</option>
              <option value="OverHead">Over-head</option>
            </select>
          </div> */}

          {/* {JSON.stringify({ form: checkCapitalProject })} */}

          {/* <div className="col-md-12">
                        <lable style={styles}>Capital Receipt </lable>
                        <input
                          className="form-control"
                          name="capital_receipt"
                          value={form.capital_receipt}
                          onChange={_handleChange}
                        />
                      </div> */}
          <div className="col-md-12">
            <CustomTypeahead
              ref={repRef}
              labelKey="head"
              label="Adminstrative Segment"
              options={aheadData}
              onChange={(selected) => {
                if (selected.length > 0) {
                  setForm((prevForm) => ({
                    ...prevForm,
                    admin_code: selected[0].code,
                    admin_description: selected[0].description,
                  }));
                }
              }}
              defaultSelected={[
                {
                  head: `${form.admin_code}-${form.admin_description}`,
                  code: form.admin_code,
                  description: form.admin_description,
                },
              ]}
            />
          </div>
          <div className="col-md-12">
            <CustomTypeahead
              ref={repRef1}
              labelKey="head"
              label="Economic Segment"
              options={aheadData2}
              onChange={(s) => {
                if (s.length) {
                  setForm((p) => ({
                    ...p,
                    economic_code: s[0].code,
                    economic_description: s[0].description,
                  }));
                  // alert(s[0].code)
                  if (form.type_of_budget === "Capital") {
                  } else {
                    getFundMap(s[0].code);
                    getFuncMap(s[0].code);
                  }
                }
              }}
              defaultSelected={[
                {
                  head: `${form.economic_code}-${form.economic_description}`,
                  code: form.economic_code,
                  description: form.economic_description,
                },
              ]}
            />
          </div>
          <div
            style={
              form.type_of_budget === "Capital"
                ? {
                    display: "none",
                  }
                : { display: "block" }
            }
            className="col-md-12"
          >
            <CustomTypeahead
              ref={repRef2}
              labelKey="head"
              label="Fund Segment"
              options={aheadData6}
              onChange={(s) => {
                if (s.length) {
                  setForm((p) => ({
                    ...p,
                    fund_code: s[0].code,
                    fund_description: s[0].description,
                  }));
                }
              }}
              defaultSelected={[
                {
                  head: `${form.fund_code}-${form.fund_description}`,
                  code: form.fund_code,
                  description: form.fund_description,
                },
              ]}
            />
          </div>
          {/* {form.project_description === "" && ( */}
          <>
            <div className="col-md-12">
              <CustomTypeahead
                ref={repRef3}
                labelKey="head"
                label="Geo Segment"
                options={aheadData3}
                onChange={(s) => {
                  if (s.length) {
                    setForm((p) => ({
                      ...p,
                      geo_code: s[0].code,
                      geo_description: s[0].description,
                    }));
                  }
                }}
                defaultSelected={[
                  {
                    head: `${form.geo_code}-${form.geo_description}`,
                    code: form.geo_code,
                    description: form.geo_description,
                  },
                ]}
              />
            </div>

            <div className="col-md-12">
              <CustomTypeahead
                ref={repRef4}
                labelKey="head"
                label="Program Segment"
                options={aheadData4}
                onChange={(s) => {
                  if (s.length) {
                    setForm((p) => ({
                      ...p,
                      program_code: s[0].code,
                      program_description: s[0].description,
                    }));
                  }
                }}
                defaultSelected={[
                  {
                    head: `${form.program_code}-${form.program_description}`,
                    code: form.program_code,
                    description: form.admin_description,
                  },
                ]}
              />
            </div>
            <div className="col-md-8">
              <CustomTypeahead
                ref={repRef5}
                labelKey="head"
                label="Functional Segment"
                options={aheadData5}
                onChange={(s) => {
                  if (s.length) {
                    setForm((p) => ({
                      ...p,
                      function_code: s[0].code,
                      function_description: s[0].description,
                    }));
                  }
                }}
                defaultSelected={[
                  {
                    head: `${form.function_code}-${form.function_description}`,
                    code: form.function_code,
                    description: form.function_description,
                  },
                ]}
              />
            </div>
          </>
          <div className="col-md-4">
            <small style={styles}>
              {title.split(" ")[0]} Recommendation{" "}
              <b>({formatNumber(form[names] || 0)})</b>
            </small>
            <input
              className="form-control"
              type="number"
              maxLength="4"
              name={names}
              value={form[names] || ""}
              onChange={handleChange}
              style={styles1}
            />
          </div>

          {/* )} */}
          {form.type_of_budget === "Capital" ? (
            <>
              <div className="col-md-8">
                <CustomTypeahead
                  ref={repRef2}
                  labelKey="head"
                  label="Fund 1 Code"
                  options={aheadData6}
                  onChange={(s) => {
                    if (s.length) {
                      setForm((p) => ({
                        ...p,
                        fund_code: s[0].code,
                        fund_description: s[0].description,
                      }));
                    }
                  }}
                  defaultSelected={[
                    {
                      head: `${form.fund_code}-${form.fund_description}`,
                      code: form.fund_code,
                      description: form.fund_description,
                    },
                  ]}
                />
              </div>
              <Col md={4}>
                <label>
                  Fund 1 Amount <b>({formatNumber(form.fund_amt1)})</b>
                </label>
                <input
                  className="form-control"
                  type="number"
                  // maxLength="4"
                  name="fund_amt1"
                  value={form.fund_amt1}
                  onChange={handleChange}
                  style={styles1}
                />
              </Col>
              <div className="col-md-8">
                <CustomTypeahead
                  ref={repRef2}
                  labelKey="head"
                  label="Fund 2 Code"
                  options={aheadDataFund}
                  onChange={(s) => {
                    if (s.length) {
                      setForm((p) => ({
                        ...p,
                        fund_code2: s[0].code,
                        fund_desc2: s[0].description,
                      }));
                    }
                  }}
                  defaultSelected={[
                    {
                      head: `${form.fund_code2}-${form.fund_desc2}`,
                      code: form.fund_code2,
                      description: form.fund_desc2,
                    },
                  ]}
                />
              </div>
              <Col md={4}>
                {" "}
                <label>
                  Fund 2 Amount <b>({formatNumber(form.fund_amt2)})</b>
                </label>
                <input
                  className="form-control"
                  type="number"
                  // maxLength="4"
                  name="fund_amt2"
                  value={form.fund_amt2}
                  onChange={handleChange}
                  style={styles1}
                />
              </Col>{" "}
              <div className="col-md-8">
                <CustomTypeahead
                  ref={repRef2}
                  labelKey="head"
                  label="Fund 3 Code"
                  options={aheadDataFund}
                  onChange={(s) => {
                    if (s.length) {
                      setForm((p) => ({
                        ...p,
                        fund_code3: s[0].code,
                        fund_desc3: s[0].description,
                      }));
                    }
                  }}
                  defaultSelected={[
                    {
                      head: `${form.fund_code3}-${form.fund_desc3}`,
                      code: form.fund_code3,
                      description: form.fund_desc3,
                    },
                  ]}
                />
              </div>
              <Col md={4}>
                {" "}
                <label>
                  Fund 3 Amount <b>({formatNumber(form.fund_amt3)})</b>
                </label>
                <input
                  className="form-control"
                  type="number"
                  style={styles1}
                  name="fund_amt3"
                  value={form.fund_amt3}
                  onChange={handleChange}
                />
              </Col>
              {/* <div className="col-md-12">
                              <CustomTypeahead
                                ref={repRef2}
                                labelKey="head"
                                label="Fund 4 Code"
                                options={aheadDataFund}
                                onChange={(s) => {
                                  if (s.length) {
                                    setForm((p) => ({
                                      ...p,
                                      fund_code4: s[0].code,
                                      // fund_description: s[0].description,
                                    }));
                                  }
                                }}
                              />
                            </div>
                            <Col md={4}>
                              <label>Fund 4 Amount</label>
                              <input
                                className="form-control"
                                type="number"
                                // maxLength="4"
                                name="fund4"
                                value={form.fund4}
                                onChange={handleChange}
                              />
                            </Col> */}
            </>
          ) : null}

          {form.type_of_budget === "Capital" ||
          form.type_of_budget === "Capital Receipt" ? (
            <div className="col-md-12">
              <lable style={styles}>
                {form.type_of_budget === "Capital Receipt"
                  ? "Reciept Description"
                  : "Project Description"}
              </lable>
              <textarea
                className="form-control"
                name="description"
                value={form.description}
                onChange={_handleChange}
                maxLength={maxLength}
                cols="10"
                rows="2"
              />
            </div>
          ) : null}
        </div>
        {/* <center className="mb-3">
          <button className="btn btn-primary pr-5 pl-5" onClick={handleAdd}>
            Add
          </button>
        </center> */}

        <center className="m-2 p-2">
          <button
            className="btn btn-primary pr-5 pl-5 ml-5"
            style={{ marginRight: "20px" }}
            onClick={handleSave}
          >
            Save
          </button>

          <Button className="ml-5" color="danger" onClick={toggle}>
            Cancel
          </Button>
        </center>
      </ModalBody>
    </Modal>
  );
}

export default BudgetForm;