/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";

import moment from "moment";
// import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { capitalizeWords } from "redux/action/api";
import { unflattenTable, unflatten } from "redux/action/api";
import {
  ButtonGroup,
  Button,
  Input,
  Modal,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import { formatNumber } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { PDFViewer } from "@react-pdf/renderer";
import { ExpenditurePdf } from "./ExpenditurePdf";
import { headerColors } from "redux/action/api";
import { getNextYear } from "../getBudgetYear";

export const newFilter = (item) => {
  return (
    item.prev_year2_actual_amount === 0 &&
    item.prev_year1_approve_amount === 0 &&
    item.prev_year1_actual_amount === 0 &&
    item.budget_amount === 0 &&
    item.approve_amount === 0
  );
};

export default function ReportsAdminShareCom({
  type = "revenue_by_admin",
  route = "/get-report",
  options = [],
  title = "",
}) {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);
  // const tableRef = useRef();
  const toggle = () => setModal(!modal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });
     useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
    code: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title, code) => {
    setSelectedOption({ value, title, code });
  };

  // const likeVar = selectedOption.title.includes("Personnel")
  //   ? "Persornnel"
  //   : selectedOption.title.includes("Capital")
  //   ? "Capital"
  //   : selectedOption.title.includes("Other NDR")
  //   ? "OverHead"
  //   : selectedOption.title.includes("Health")
  //   ? "04%"
  //   : selectedOption.title.includes("Debt")
  //   ? selectedOption.code
  //     ? selectedOption.code
  //     : "2206%"
  //   : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}&like=${selectedOption.code}`,
      (data) => {
        let _data = data?.results;
        const tt = unflattenTable(_data);
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, selectedOption.code]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  // const headerColors = [
  //   "#999",
  //   "#ccc",
  //   "#eee",
  //   "#f2f2f2",
  //   "#f5f5f5",
  //   "#f9f9f9",
  // ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const calculateAdjustment = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.supplementary_amount;
    } else {
      let sum = node.supplementary_amount || 0;
      for (const child of node.children) {
        sum += calculateAdjustment(child);
      }
      return sum;
    }
  };

  const newFilterHead = (item) => {
    return (
      calculateprevyear(item) === 0 &&
      calculateApprovePrev1(item) === 0 &&
      calculateActualPrev1(item) === 0 &&
      calculateBudgetAmt(item) === 0 &&
      calculateApproveAmt(item) === 0
    );
  };

  const tableRef = useRef(null);
  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "2000000000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "5000000000000000000" ||
        item.code === "7000000000000000000" ||
        item.code === "8000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          {newFilterHead(item) ? null : (
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                toggle();
                setSelectMDA(item);
                // console.log(item);
              }}
            >
              {/* {isTitle ? "" : item.code} */}
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle
                  ? ""
                  : item.description === "Water Resources and Rural Development"
                  ? "10"
                  : item.description === "CLIMATE CHANGE"
                  ? "20"
                  : item.code.replace(/0+$/, "")}
              </td>
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle ? selectedOption.title : item.description}
                {/* {form.type} */}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateprevyear(item))
                  ? "---"
                  : formatNumber(calculateprevyear(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateApprovePrev1(item))
                  ? "---"
                  : formatNumber(calculateApprovePrev1(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateActualPrev1(item))
                  ? "---"
                  : formatNumber(calculateActualPrev1(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateBudgetAmt(item))
                  ? "---"
                  : formatNumber(calculateBudgetAmt(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateAdjustment(item))
                  ? "---"
                  : formatNumber(calculateAdjustment(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(calculateApproveAmt(item))
                  ? "---"
                  : formatNumber(calculateApproveAmt(item))}
              </td>
            </tr>
          )}

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else if (newFilter(child)) {
              return null;
            } else {
              return (
                <tr key={child.code} style={{ fontSize: "11px" }}>
                  <td>{child.code}</td>
                  <td>{child.description}</td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.prev_year2_actual_amount) || 0
                      ? "---"
                      : formatNumber(child.prev_year2_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.prev_year1_approve_amount) || 0
                      ? "---"
                      : formatNumber(child.prev_year1_approve_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.prev_year1_actual_amount) || 0
                      ? "---"
                      : formatNumber(child.prev_year1_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.budget_amount) || 0
                      ? "---"
                      : formatNumber(child.budget_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.supplementary_amount) || 0
                      ? "---"
                      : formatNumber(child.supplementary_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.approve_amount) || 0
                      ? "---"
                      : formatNumber(child.approve_amount)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };

    return (
      <table className="table table-bordered" ref={tableRef}>
        <thead>
          <tr
            style={{
              fontSize: "12px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
              backgroundColor: "orange",
            }}
          >
            <th style={{ backgroundColor: "orange" }}>Code</th>
            <th style={{ backgroundColor: "orange" }}> Description</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 2
            } Full Year Actuals`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Aproved`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Performance Jan. to Aug.`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} MDA Proposal`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Adjustments`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>{treeData.map((item, i) => renderItems(item, i))}</tbody>
        {/* {JSON.stringify(treeData.length)} */}
      </table>
    );
  };

  const [pdf, setPdf] = useState(false);

  return (
    <>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  size="lg"
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <MDAReportModal
                    toggle={toggle}
                    mda_name={selectMDA.description}
                  />
                </Modal>
              </div>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                  className="d-flex justify-content-between "
                >
                  <MDTypography variant="h5" color="white">
                    {capitalizeWords(selectedOption.title)}
                  </MDTypography>

                  {/* {JSON.stringify(treeData)} */}
                  <div>
                    {pdf ? (
                      <Button onClick={() => setPdf(!pdf)} color="danger">
                        Close
                      </Button>
                    ) : (
                      <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                        <DropdownToggle
                          className="btn btn-primary text-white ml-3"
                          caret
                        >
                          <FaFileDownload
                            color="white"
                            size="1.2rem"
                            className="mr-5"
                          />
                          Download
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => console.log("Option 1 clicked")}
                          >
                            <DownloadTableExcel
                              filename={selectedOption.title}
                              sheet={selectedOption.title}
                              currentTableRef={tableRef.current}
                            >
                              Export to Excel
                            </DownloadTableExcel>
                          </DropdownItem>
                          <DropdownItem onClick={() => setPdf(!pdf)}>
                            PDF
                          </DropdownItem>
                          <DropdownItem />
                          <DropdownItem
                            onClick={() =>
                              console.log("Another option clicked")
                            }
                          >
                            Word
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                </MDBox>
                {pdf ? (
                  <PDFViewer height={1000} width={1200}>
                    <ExpenditurePdf data={treeData} />
                  </PDFViewer>
                ) : (
                  <MDBox pt={3}>
                    {/* {JSON.stringify({ selectedOption })} */}
                    <div
                      className="d-flex justify-content-between m-1 row table-responsive"
                      style={{ marginRight: 10, marginLeft: 10 }}
                    >
                      <div className="col-sm-2 mb-2">
                        <Input
                          type="select"
                          name="budget_year"
                          value={form.budget_year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>
                      <div className="col-sm-10 mb-2">
                        <div className="text-center">
                          {/* <label>Select the report type</label> */}
                        </div>
                        <center>
                          <ButtonGroup>
                            {options.map((item) => (
                              <Button
                              style={{lineHeight:'12px'}}
                                size="sm"
                                key={item.title}
                                outline={
                                  selectedOption.title !== item.title
                                    ? true
                                    : false
                                }
                                color={
                                  selectedOption.title === item.title
                                    ? "primary"
                                    : "secondary"
                                }
                                onClick={() =>
                                  handleButtonClick(
                                    item.value,
                                    item.title,
                                    item.code
                                  )
                                }
                              >
                                <small>{item.title}</small>
                              </Button>
                            ))}
                          </ButtonGroup>
                        </center>
                      </div>
                    </div>
                    <div className="p-3">
                      {/* {JSON.stringify(treeData)} */}
                      <Scrollbar>
                        {loading && (
                          <div className="loading-container text-center">
                            <Spinner
                              color="primary"
                              style={{ marginTop: "20px" }}
                            />{" "}
                            Loading...
                          </div>
                        )}
                        {generateTable2()}
                      </Scrollbar>
                    </div>
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
